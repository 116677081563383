<template>
<div>
  <div class="page-size page-langues">
    <page-corners/>
    <div>
      <h2>Daty manan-danja ny</h2>
      <h1>fanjanahantany</h1>
      <languages/>
    </div>
  </div>
</div>
</template>

<script>
import Languages from "../langues/languages";
import PageCorners from "../decoration/PageCorners";
export default {
  name: "page-langues",
  components: {PageCorners, Languages}
}
</script>


<style lang="less">
.page-langues{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1,h2{
    line-height: 1.5;
    margin: 0;
    font-weight: normal;
  }
  h1{
    font-size: 4.8vw;
  }
  .languages{
    margin-top: 3vw;
  }


}
</style>