<template>
    <!-- Generator: Adobe Illustrator 23.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">

        <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FF0000" stroke-width="0.5" stroke-miterlimit="10" points="
	24,30 14,20 24,10 "/>
</svg>

</template>

<script>
    export default {
        name: "IconCaretLeft"
    }
</script>

<style scoped>

</style>