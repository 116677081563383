<template>
  <div class="photo photo-component"
  :style="{
    '--position':photo.position
  }">
    <img :src="photo.src"
         :alt="photo.url" />
    <legende-deco>
      <span v-html="photo.legende.val"/>
    </legende-deco>
  </div>
</template>

<script>
import PhotoField from "@/models/fields/PhotoField";
import LegendeDeco from "@/decoration/legende-deco";

export default {
  name: "photo",
  components: {LegendeDeco},
  props:{
    photo:{
      type:PhotoField
    }
  }
}
</script>

<style lang="less">
.photo-component{
  transition: opacity 1s;
  opacity: 0;
  &:after
  {
    //outline: 1px solid red;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.01) 1%,rgba(0,0,0,0.65) 100%);
    content: '';
    display: block;
    height: 100px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  img{
    object-position: var(--position);
    transition: filter 2s;
    filter: brightness(2) saturate(0);
  }
  .legende-deco{
    z-index: 2;
    display: none !important;
  }
  &.active{
    opacity: 1;
    img{
      filter: brightness(1) saturate(1);
    }
    .legende-deco{
      display: flex !important;
    }
  }
}
</style>