<template>
    <!-- Generator: Adobe Illustrator 24.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
        <polyline clip-rule="evenodd"
                  style="fill:none;stroke:#FF1800;stroke-width:0.5;stroke-miterlimit:10;"
                  points="11,18.0 11,30.5
                          17,30.5 17,24.5
                          22,24.5 22,30.5
                          28,30.5 28,18.0
"/>
        <polyline clip-rule="evenodd"
                  style="fill:none;stroke:#FF1800;stroke-width:0.5;stroke-miterlimit:10;"
                  points="31,17
                          19.5,10
                          8,17 "/>
</svg>
</template>

<script>
    export default {
        name: "IconHome"
    }
</script>

<style scoped>

</style>