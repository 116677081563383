import Record from "@/models/Record";
import TranslatedField from "@/models/fields/TranslatedField";
import PhotoField from "@/models/fields/PhotoField";

export default class DateRecord extends Record{
    constructor(json) {
        super();
        this.json=json;
        this.id=json.id;
        /**
         * @type {string}
         */
        this.periode=json.periode;
        this.titre=new TranslatedField(json.titre);
        this.sstitre=new TranslatedField(json.sstitre);
        this.texte=new TranslatedField(json.texte);
        /**
         *
         * @type {PhotoField[]}
         */
        this.photos=[];
        for(let p of json.photos){
            this.photos.push(new PhotoField(p));
        }
    }

    /**
     * Est ce que c'est la page active ?
     * @return {boolean}
     */
    get isActive(){
        return String(window.$layout.currentPageId) === String(this.id);
    }

    /**
     * Est ce que c'est la cover active?
     * @return {boolean}
     */
    get isCoverActive(){
        let active=window.$layout.activePageDate;
        return active && String(active.cover.id) === String(this.id);
    }
    /**
     * Est ce un page principale (rouge et affiché dans la nav en bas)
     * @return {boolean}
     */
    get isCover(){
        return !!this.periode;
    }

    get url(){
        return `/${window.$lang.currentLocale.code}/date/${this.id}`;
    }

    /**
     * Renvoie la page cover qui correspond à cette page
     * @return {DateRecord}
     */
    get cover(){
        if(this.isCover){
            return this;
        }
        /**
         *
         * @type {DateRecord[]}
         */
        let reversed = [].concat(window.$db.dateRecords).reverse();
        let found=false;
        for(let p of reversed){
            if(found && p.isCover){
                return p;
            }
            if(p === this){
                found=true;
            }
        }
        return null;
    }
    /**
     *
     * @return {DateRecord|null}
     */
    get next(){
        let found=false;
        for(let p of window.$db.dateRecords){
            if(found){
                return p;
            }
            if(p === this){
                found=true;
            }
        }
        return null;
    }

    /**
     *
     * @return {null|DateRecord}
     */
    get prev(){
        let found=false;
        let reversed = [].concat(window.$db.dateRecords).reverse();
        for(let p of reversed){
            if(found){
                return p;
            }
            if(p === this){
                found=true;
            }
        }
        return null;
    }
}