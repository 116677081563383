export default class TranslatedField{
    constructor(json) {
        /**
         *
         * @type {string}
         */
        this.fr=json.fr;
        /**
         *
         * @type {string}
         */
        this.en=json.en;
        /**
         *
         * @type {string}
         */
        this.mg=json.mg;
    }

    /**
     * La valeur dans la langue courrante
     * @return {string}
     */
    get val(){
        return this[window.$lang.currentLocale.code];
    }

}