<template>
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
        <line fill="none" stroke="#FF0000" stroke-width="0.5" stroke-miterlimit="10" x1="10" y1="10" x2="30" y2="30"/>
        <line fill="none" stroke="#FF0000" stroke-width="0.5" stroke-miterlimit="10" x1="30" y1="10" x2="10" y2="30"/>
    </svg>
</template>

<script>
    export default {
        name: "IconClose"
    }
</script>

<style scoped>

</style>