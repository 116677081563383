<template>
  <div id="app">
    <veille v-if="$layout.veille"/>
    <transition
        v-on:before-enter="beforeEnter"
        v-on:enter="enter"
        v-on:leave="leave"
        v-bind:css="false"
    >
      <router-view :key="$route.fullPath"/>
    </transition>

    <nav-dates
        v-if="!$layout.isHome"
        class="animate__animated animate__fadeInUp"
    />
  </div>
</template>

<script>

import NavDates from "@/nav/NavDates";
import {gsap, Power3} from "gsap";
import Veille from "@/components/veille";
export default {
  name: 'App',
  components: {
    Veille,
    NavDates
  },
  data(){
    return{
      "transitionIn":"fadeIn",
      "transitionOut":"fadeOut",
      transiParams:{
        duration:1
      }

    }
  },
  onIdle() {
    console.log("paused")
    this.$layout.veille=true;
    this.$layout.goHome();
  },
  onActive() {
    this.$layout.veille=false;
    console.log("active")
  },
  mounted() {
    this.updatePageActive();
  },
  watch:{
    $route(to, from){
      this.updatePageActive();
      console.log("transi watch",from.fullPath,to.fullPath)
      if(to.name==="home" || from.name==="home"){
        this.transitionIn="fadeIn";
        this.transitionOut="fadeOut";
      }else{
        for(let page of this.$db.dateRecords){
          console.log("test",page.id,from.params.id,to.params.id);
          if(String(page.id)===String(to.params.id)){
            this.transitionOut="slideOutRight";
            this.transitionIn="slideInLeft";
            return;
          }
          if(String(page.id)===String(from.params.id)){
            this.transitionOut="slideOutLeft";
            this.transitionIn="slideInRight";
            return;
          }
        }
      }
    }
  }, methods:{
    updatePageActive(){
      this.$layout.currentPageId=this.$route.params.id;
    },
    beforeEnter: function (el) {
      console.log("beforeEnter",this.$router.currentRoute,el);

      //el.style.opacity = 0
      //el.style.transformOrigin = 'left'
    },
    enter: function (el, done) {
      console.log("enter",el,done);
      let tl=gsap.timeline({onComplete:done});
      let moveBy=screen.width*1.5;

      switch (this.transitionIn) {
        case "fadeIn":
          tl.to(el,{
                opacity:0,
                duration:0
              }
          );
          tl.to(el,{
                opacity:1,
                duration:1,
                delay:0.5
              }
          );
          break;
        case "slideInLeft":
          tl.to(el,{x:-moveBy,duration:0});
          tl.to(el,{
            x:0,
            duration:this.transiParams.duration,
            delay:0.0,
            ease:Power3.easeInOut
          });
          break;
        case "slideInRight":
          tl.to(el,{x:moveBy,duration:0});
          tl.to(el,{
            x:0,
            duration:this.transiParams.duration,
            delay:0.0,
            ease:Power3.easeInOut
          });
          break;
      }

      //Velocity(el, { opacity: 1, fontSize: '1.4em' }, { duration: 300 })
      //Velocity(el, { fontSize: '1em' }, { complete: done })
    },
    leave: function (el, done) {
      let moveBy=screen.width*1.2;
      console.log("leave",el,done);
      let tl=gsap.timeline({onComplete:done});
      switch (this.transitionOut) {
        case "fadeOut":
          tl.to(el, {
            opacity: 0,
            duration: this.transiParams.duration / 2
          });
          break;
        case "slideOutLeft":
          tl.to(el, {
            x:-moveBy,
            duration:this.transiParams.duration,
            ease:Power3.easeInOut
          });
          break;
        case "slideOutRight":
          tl.to(el, {
            x:moveBy,
            duration:this.transiParams.duration,
            ease:Power3.easeInOut
          });
          break;
      }
    }
  },

}
</script>

<style lang="less">
@import "style-guide/typographie";
@import "~@material/elevation/dist/mdc.elevation.min.css";
@import "~animate.css";
html,body,*{
  box-sizing: border-box;
}
body{

  --page-w:1720px;
  --page-h:880px;
  --nav-height:70px;

  height: 100vh;
  background-color: #491114;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./assets/bg-dates.jpg");
  color: #fff;
  overflow: hidden;
}
#app {
  //reset----------------------
  width: 100%;
  height: 100%;
  .page-size{
    position: absolute;
    left: calc(50vw - var(--page-w) / 2);
    top: calc(50vh - var(--page-h) / 2 - 20px);
    width: var(--page-w);
    height: var(--page-h);
  }
  .nav-dates{
    //outline: 1px solid red;
    height: var(--nav-height);
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
  }
}
</style>
