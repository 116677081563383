<template>
<div class="page-size page-date mdc-elevation--z10"
     :class="{
        'zoomed':this.zoomed,
        'is-cover':this.page.isCover
      }">
  <div class="left-right">
    <div class="left">
      <h1 v-html="page.titre.val"/>
      <h2 v-html="page.sstitre.val"/>
      <p v-html="page.texte.val"/>
    </div>
    <div class="right">
      <photos>
        <photo v-for="(ph,i) of page.photos"
               :key="'photo-'+i"
               :photo="ph"
        />
      </photos>
    </div>
  </div>
  <nav>
    <a v-if="page.prev"
       is="router-link" :to="page.prev.url"
       class="prev">
      <icon-base size="4vw" icon-color="#fff">
        <icon-caret-left/>
      </icon-base>
    </a>

    <a v-if="page.next"
       is="router-link" :to="page.next.url"
       class="next">
      <icon-base size="4vw" icon-color="#fff">
        <icon-caret-right/>
      </icon-base>
    </a>

    <a class="zoom"
       href="#" @click.prevent="zoomed=!zoomed" >
      <icon-base size="4vw" icon-color="var(--text-color)">
        <icon-close/>
      </icon-base>
    </a>

    <a v-if="!zoomed"
       class="close" href="#"
       @click.prevent="$router.push('/')">
      <icon-base size="3vw" icon-color="var(--text-color)">
        <icon-close/>
      </icon-base>
    </a>

  </nav>
</div>
</template>

<script>
import IconBase from "@/icons/IconBase";
import IconCaretLeft from "@/icons/IconCaretLeft";
import IconCaretRight from "@/icons/IconCaretRight";
import IconClose from "@/icons/IconClose";
import Photos from "@/components/photos";
import Photo from "@/components/photo";
export default {
  name: "page-date",
  components: {Photo, Photos, IconClose, IconCaretRight, IconCaretLeft, IconBase},
  data(){
    return {
      zoomed:false
    }
  },
  computed:{
    /**
     * La page en cours
     * @return {DateRecord}
     */
    page(){
      return this.$db.dateRecords.find(rec => String(rec.id) === String(this.$route.params.id));
    }
  },
}
</script>

<style lang="less">
@import "page-date-colors";
@import "page-dates-left";
.page-date{
  .left-right{
    width: 100%;
    height: 100%;
    position: relative;
    .left,.right{
      position: absolute;
      height: 100%;
      width: 50%;
    }
    .right{
      right: 0;
    }
  }
  .right{
    position: relative;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .legende-deco{
      position: absolute;
      bottom: 1vw;
      text-align: center;
      width: 100%;
    }
  }

  nav{
    --icon-size:60px;
    pointer-events: none;
    *{
      pointer-events: auto;
    }
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;

    svg{
      pointer-events: none;
      //width: var(--icon-size);
      //height:var(--icon-size);
    }

    >*{
      position: absolute;
      //color: #fff;
    }
    .prev,.next{
      top:calc(50% -  var(--icon-size) / 2);
    }
    .prev{
      left:calc( -1 * var(--icon-size) - 1vw);
    }
    .next{
      right:calc( -1 * var(--icon-size) - 1vw);
    }
    .close{
      --icon-size:3vw;
      width: var(--icon-size);
      height: var(--icon-size);
      top: 1.2vw;
      left: 1.2vw;
    }

    .zoom{
      top: 0;
      right: 0;
      padding: 0;
      border: none;
      width: 85px;
      height: 85px;
      --text-color: #231a19 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      >*{
        transition: all 0.5s;
        transform: rotate(45deg) scale(0.5);
        display: block;
        margin: 0;
      }
    }
    .right{
      transition: all 0.5s;
    }
  }
  .right{
    transition: all 0.5s;
  }
  &.zoomed {
    .zoom {
      > * {
        transform: rotate(0deg);
      }
    }
    .right {
      width: 100% !important;
    }
  }
}
</style>