export default class Locale {

    /**
     * A language
     * @param {string} code iso code (fr, en, es, etc..)
     * @param {string} label The displayed language name (english, français, español, etc..)
     * @param {string} flagImg Flag image url (or not)
     */
    constructor(code,label,flagImg) {
        /**
         * Iso code
         * @type {String}
         * @example fr, en, it, es etc...
         */
        this.code=code;
        /**
         * How it should be displayed to humans
         * @type {String}
         * @example Français, English, Español...
         */
        this.label=label;
        /**
         * Related image
         * @type {String}
         * @example require("svg-country-flags/svg/fr.svg"))
         */
        this.flagImg=flagImg;
        /**
         * Is it the currently user choosen language ?
         * @type {boolean}
         */
        this._isCurrent=false;
    }
    get isCurrent() {
        return this._isCurrent;
    }

}