import TranslatedField from "@/models/fields/TranslatedField";

export default class PhotoField{
    constructor(json) {
        this.url=json.url;
        this.legende=new TranslatedField(json.legende)
        this.position=json.position;
    }
    get src(){
        if(document.location.protocol.indexOf("http")>-1){
            return "https://mada-photos.pixel-vinaigrette.com/chrono/"+this.url;
        }else{
            return "photos/"+this.url;
        }

    }
}