<template>
  <div class="photos">
    <slot/>
  </div>
</template>

<script>

export default {
  name: "photos",
  data(){
    return {
      loop:null,
      z:10,
      imgs:[]
    }
  },
  mounted(){
    let me=this;
    this.$el.querySelectorAll(".photo").forEach(function($p){
      me.imgs.push($p);
    });
    if(this.imgs.length>0){
      this.imgs[0].classList.add("active");
      if(this.imgs.length>1){
        this.loop=setInterval(me.crossfade,5000)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.loop);
  },
  methods:{
    crossfade(){
      this.imgs[0].classList.remove("active");
      this.imgs[1].classList.add("active");
      this.imgs.push( this.imgs.shift() );
    }
  }
}
</script>

<style lang="less">
.photos{
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
</style>