<template>
    <svg class="icon-base" xmlns="http://www.w3.org/2000/svg"
         :style="`background-color: ${backgroundColor}`"
         :width="widthSmart"
         :height="heightSmart"
         :aria-labelledby="iconName"
         role="presentation"

    >
        <g :fill="iconColor" :style="`color: ${iconColor}`">
          <slot />
        </g>

  </svg>
</template>

<script>
    export default {
        name: "IconBase",
        props: {
            iconName: {
                type: String,
                default: 'box'
            },
            width: {
                type: [Number, String],
                default: 18
            },
            height: {
                type: [Number, String],
                default: 18
            },
            size:{
                type: [Number, String],
                default: null
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            },
            backgroundColor: {
                type: String,
                default: 'transparent'
            }
        },
        computed:{
            widthSmart(){
                if(this.size){
                    return this.size;
                }
                return this.width;
            },
            heightSmart(){
                if(this.size){
                    return this.size;
                }
                return this.height;
            }
        }
    }
</script>

<style lang="less">
    .icon-base{
        //background-color: yellow;
        polyline,line{
            //fill:currentColor !important;
            stroke:currentColor !important;
        }
        pointer-events: none;
    }
</style>