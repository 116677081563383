<template>
<div class="veille">
  <video src="veille.mp4" muted autoplay loop/>
</div>
</template>

<script>
export default {
  name: "veille"
}
</script>

<style lang="less">
.veille{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  video{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>