<template>
    <a is="router-link" :to="page.url"
       class="nav-date-item"
            v-bind:class="{'is-active':page.isCoverActive}"
            v-on:click="$emit('select-item',page)">
        <span class="square">
            <span class="text">{{page.periode}}</span>
        </span>
    </a>
</template>

<script>
    import DateRecord from "@/models/DateRecord";

    export default {
        name: "NavDateItem",
        props:{
          'page':{
            type:DateRecord
          }
        },
        data(){
          return {}
        },
        methods:{}
    }
</script>

<style scoped lang="less">
    .nav-date-item{
        cursor: pointer;
        outline: none;
        position: relative;
        background: transparent;
        border: none;
        color: #fff;
        width: 55px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: normal;

        .text{
          opacity: 0.3;
          font-family: var(--typo-numbers);
          font-weight: normal;
        }
        &.is-active{
          .text{
            opacity: 1;
          }
        }

        &.router-link-active{

        }
        .square{
            transform: rotate(-45deg);
            top: 0;
            left: 0;
            width: 0.7vw;
            height: 0.7vw;
            background-color: #fff;
            >.text{
              white-space: nowrap;
              //outline: 1px solid red;
              font-size: 14px;
                display: block;
                position: absolute;
                bottom: 1vw;
            }
        }

    }
</style>