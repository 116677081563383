<template>
    <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">

        <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FF0000" stroke-width="0.5" stroke-miterlimit="10" points="
	-35,30 -45,20 -35,10 "/>
        <polyline fill-rule="evenodd" clip-rule="evenodd" fill="none" stroke="#FF0000" stroke-width="0.5" stroke-miterlimit="10" points="
	15,10 25,20 15,30 "/>
</svg>

</template>

<script>
    export default {
        name: "IconCaretRight"
    }
</script>

<style scoped>

</style>