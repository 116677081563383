import Vue from 'vue'
import VueRouter from "vue-router";
import PageDate from "../pages/page-date";
import PageLanguages from "../pages/page-langues";

const router = new VueRouter({
    routes: [
        {
            name:"home",
            path: '/',
            component: PageLanguages
        },
        {
            name:"date",
            path: '/:lang/date/:id',
            component: PageDate
        },

    ]
});
Vue.use(VueRouter);
export default router;