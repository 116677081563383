import DateRecord from "@/models/DateRecord";

const axios = require('axios').default;

export default class Db{
    constructor() {
        /**
         *
         * @type {DateRecord[]}
         */
        this.dateRecords=[];
    }

    /**
     *
     * @return {DateRecord[]}
     */
    get covers(){
        return this.dateRecords.filter(p=>p.isCover);
    }

    /**
     * Monte la bdd à partir du json transmis
     * @param json
     */
    mount(json){
        for(let d of json){
            d=new DateRecord(d);
            this.dateRecords.push(d);
        }
    }
    importLocal(cb){
        this.mount(require("./data.json"));
        cb();
    }
    importWeb(cb){
        let me=this;
        axios.post(`https://mada-chrono.pixel-vinaigrette.com/php/data.php`,
            {rand:Math.random()}
        )
            .then(function (response) {
                console.log(response.data);
                me.mount(response.data);
                cb();
            })
            .catch(function (error) {
                console.error(error);
            })
            .then(function () {
                // always executed
            });
    }

}