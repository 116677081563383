<template>
    <nav class="nav-dates">
        <div class="line"></div>
        <nav-date-item
            v-for="page in $db.covers"
            :key="page.id"
            :page="page"
            v-on:select-item="$layout.goPage(page)"
        ></nav-date-item>
        <a class="home" href="#" @click.prevent="$layout.goHome()">
          <icon-base size="3vw" icon-color="#fff">
              <icon-home/>
          </icon-base>
        </a>
    </nav>
</template>

<script>
    import NavDateItem from "./NavDateItem";
    import IconBase from "../icons/IconBase";
    import IconHome from "../icons/IconHome";
    export default {
        name: "NavDates",
        components: {NavDateItem,IconBase,IconHome},
    }
</script>

<style scoped lang="less">
    nav{
        //outline: 1px solid yellow;
        user-select: none;
        //padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .line{
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: white;
            margin: 0;
        }
        .home{
            svg{
                display: block;
            }
            position: absolute;
            right: 0vw;
            top: calc(50% - 3vw);
        }
    }
</style>