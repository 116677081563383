export default class Layout{
    constructor() {
        this.currentPageId=null;
        this.veille=false;
    }
    goHome(){
        if(window.$router.currentRoute.name !== "home"){
            window.$router.push("/");
        }

    }

    /**
     *
     * @param {DateRecord} page
     */
    goPage(page){
        console.log("goPage", page.url)
        if(window.$router.currentRoute.params.id !== page.id) {
            window.$router.push(page.url);
        }
    }

    get isHome(){
        return window.$router.currentRoute.name === "home";
    }

    /**
     * Renvoie la page date active
     * @return {DateRecord|null}
     */
    get activePageDate(){
        if(!this.currentPageId){
            return null;
        }
        return window.$db.dateRecords.find(
            p=> String(p.id)===String(this.currentPageId)
        );
    }
}