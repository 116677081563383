const EventEmitter = require('event-emitter-es6');
import Locale from "./Locale";

/**
 * Do you prefer William Shakespeare or Victor Hugo ?
 * This singleton deals with locales
 * @see Locale
 */
export default class LocalesManager extends EventEmitter{
    constructor() {
        super();
        /**
         * Available locales in this project
         * @type {Locale[]}
         */
        this.locales=[];
        /**
         * The current locale
         * @type {Locale}
         */
        this._currentLocale=null;
    }

    /**
     * To define the current locale by string code
     * @param {string} code
     */
    set currentLocaleCode(code){
        this.currentLocale=code;
    }
    /**
     *  To get the current locale string code
     * @return {String}
     */
    get currentLocaleCode(){
        return this.currentLocale.code;
    }

    /**
     * The current locale object
     * @return {Locale}
     */
    get currentLocale() {
        return this._currentLocale;
    }
    /**
     * Define the current locale
     * @param {string|Locale} value
     */
    set currentLocale(value) {
        let lng;
        let changed=false;
        if(typeof value === "string"){
            lng = this.getLocale(value);
        }else{
            lng=value;
        }
        if(JSON.stringify(lng) !== JSON.stringify(this._currentLocale)){
            changed=true;
        }
        this._currentLocale = lng;
        for(let l of this.locales){
            l._isCurrent=false;
        }
        this._currentLocale._isCurrent=true;
        if(changed){
            this.emit("change-current-language")
        }
    }
    /**
     * Add a locale to the list
     * @param {string} code iso code (fr, en, es, etc..)
     * @param {string} label The displayed language name (english, français, español, etc..)
     * @param {string|null} flagImg Flag image url (or not) Example require("svg-country-flags/svg/fr.svg"))
     */
    addLocale(code, label, flagImg=null){
        let existing=this.getLocale(code);
        if(!existing){
            this.locales.push(
                new Locale(code,label,flagImg)
            );
        }else{
            console.warn("addLocale already defined !",existing)
        }

    }
    /**
     * Returns a locale from locale code (fr,en, es etc..)
     * @param {string} langCode iso code (fr, en, es, etc..)
     * @return {Locale}
     */
    getLocale(langCode){
        return this.locales.find(item=>item.code===langCode);
    }
}