<template>
    <a href="#"
       class="btn-flag"
       @click.prevent="$emit('change',locale)"
    >
        <div class="losange animate__animated animate__zoomIn"
             :style="{
               'background-image':`url('${locale.flagImg}')`
             }"

        />
      <span class="animate__animated animate__fadeInUp">
        {{locale.label}}
      </span>

    </a>
</template>

<script>
import Locale from "../models/Locale";
    export default {
        name: "BtnFlag",
        props:{
            "locale":{
                type:Locale,
            }
        },
        data() {return {}},
        computed:{}
    }
</script>

<style lang="less">
    .btn-flag{
        text-decoration: none;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 75px;
        color: #fff;
        &.active{
            font-weight: bold;
        }
        .losange{
            pointer-events: none;
            width: 100px;
            height: 100px;
            position: relative;
            margin-bottom: 35px;
            background-size: cover;
        }


    }
</style>