
<template>
    <div class="page-corners">
        <div class="corners">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
        <div class="borders">
            <i></i>
            <i></i>
            <i></i>
            <i></i>
        </div>
    </div>
</template>

<script>

    export default {
        name: "PageCorners"
    }
</script>

<style lang="less">
    .page-corners{
        opacity: 0.3;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .corners,.borders{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            >i{
                display: block;
                position: absolute;
            }
        }
        @sz:0.5vw;
        .corners{

            >i{

                width: @sz;
                height: @sz;
                transform: rotate(45deg);
                background-color: white;
                &:first-child{
                    top:calc( -0.5 * @sz );
                    left: calc( -0.5 * @sz );
                }
                &:nth-child(2){
                    top: calc( -0.5 * @sz );
                    right: calc( -0.5 * @sz );
                }
                &:nth-child(3){
                    bottom: calc( -0.5 * @sz );
                    right: calc( -0.5 * @sz );
                }
                &:nth-child(4){
                    bottom:calc( -0.5 * @sz );
                    left: calc( -0.5 * @sz );
                }
                animation-name: kf-corners;
                animation-iteration-count: 1;
                animation-duration: 6s;
                @keyframes kf-corners{
                    from{
                        transform: rotate(-90deg) scale(0);
                    }
                    to{
                        transform: rotate(45deg) scale(1);
                    }
                }
            }
        }

        .borders{



            >i{
                background-color: white;
                @decalage:0.7vw;
                &:first-child{
                    top:  0;
                    left:  @decalage;
                    right:  @decalage;
                    height: 1px;
                }
                &:nth-child(2){
                    top: @decalage;
                    bottom: @decalage;
                    right: -1px;
                    width: 1px;
                }
                &:nth-child(3){
                    bottom: -1px;
                    height: 1px;
                    left:  @decalage;
                    right:  @decalage;
                }
                &:nth-child(4){

                    left: 0;
                    width: 1px;
                    top: @decalage;
                    bottom: @decalage;
                }


                animation-name: kf-entree;
                animation-iteration-count: 1;
                animation-duration: 5s;
                @keyframes kf-entree{
                    from{
                        transform: scaleX(0.1) scaleY(0.1);
                    }
                    to{
                        transform: scaleX(1) scaleY(1);
                    }
                }

            }
        }



    }
</style>