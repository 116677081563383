<template>
    <div class="legende-deco">
        <div class="texte"><slot/></div>
        <div class="deco">
        </div>
    </div>
</template>

<script>

    export default {
        name: "LegendeDeco"
    }

</script>

<style lang="less">
    .legende-deco{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .texte{
            font-family: var(--typo-text);
            margin-bottom: 1vw;
            font-size: 0.6vw;

            animation-name: kf-apparition-texte;
            animation-duration: 2s;
            animation-iteration-count: 1;
            @keyframes kf-apparition-texte{
                0%{
                    transform: translateY(20px);
                    opacity: 0;
                }
                75%{
                    transform: translateY(20px);
                    opacity: 0;
                }
                100%{
                    transform: translateY(0px);
                    opacity:1;
                }
            }


        }
        .deco{
            position: relative;
            &:before{
                display: block;
                position: absolute;
                left: -5vw;
                content: "";
                width: 10vw;
                height: 1px;
                background-color: white;

                animation-name: kf-apparition;
                animation-duration: 2s;
                animation-iteration-count: 1;
                @keyframes kf-apparition{
                    0%{
                        transform: scale(0);
                    }
                    50%{
                        transform: scale(0);
                    }
                    100%{
                        transform: scale(1);
                    }
                }
            }



            &:after{
                display: block;
                position: absolute;
                content: "";
                top: calc(50% - @sz / 2 + 1px);
                left: calc(50% - @sz / 2);
                @sz:0.5vw;

                background-color: #fff;
                width: @sz;
                height: @sz;
                transform: rotate(45deg);

                animation-name: kf-apparition-2;
                animation-duration: 2s;
                animation-iteration-count: 1;
                @keyframes kf-apparition-2{
                    0%{
                        transform: scale(0) rotate(45deg);
                    }
                    75%{
                        transform: scale(0) rotate(45deg);
                    }
                    100%{
                        transform: scale(1) rotate(45deg);
                    }
                }

            }
        }
    }
</style>